import { isJson } from 'utils/json'

type HTTPMethods = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH'

export interface PromiseWrapperOptions {
  contentType?: string
  withCredentials?: boolean
  noJson?: boolean
}

export function promiseWrapper<T, P>(
  url: string,
  method: HTTPMethods,
  data?: P,
  options?: PromiseWrapperOptions,
): Promise<T> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open(method, url)
    if (method === 'POST' || method === 'PUT')
      xhr.setRequestHeader('Content-type', options?.contentType || 'application/json')
    xhr.onload = () => {
      if ([200, 201, 202].includes(xhr.status)) {
        resolve(xhr.response && isJson(xhr.response) ? JSON.parse(xhr.response) : xhr.response)
      } else {
        reject(xhr)
      }
    }
    if (options?.withCredentials) {
      xhr.withCredentials = true
    }
    xhr.onerror = () => reject(xhr.statusText)
    xhr.send(data ? (options?.noJson ? data.toString() : JSON.stringify(data)) : null)
  })
}
